import React from 'react'
import PropTypes from 'prop-types'

const Abstract = ({ color, negative }) => (
	<svg
		width="55.9"
		height="81.7"
		viewBox="0 0 55.9 81.7"
		fill="none"
		id="abstract"
	>
		<path
			fill={color}
			d="M10.7,81.7c-1.2,0-2.1-1-2.1-2.2V62.6c0-1.2,1-2.2,2.1-2.2s2.1,1,2.1,2.2v16.9C12.9,80.7,11.9,81.7,10.7,81.7z"
		/>
		<path
			fill={color}
			d="M2.1,70.4c-1.2,0-2.1-1-2.1-2.2V55.6c0-1.2,1-2.2,2.1-2.2s2.1,1,2.1,2.2v12.7C4.3,69.4,3.3,70.4,2.1,70.4z"
		/>
		<path
			fill={color}
			d="M2.1,81.7c-1.2,0-2.1-1-2.1-2.2v-2.8c0-1.2,1-2.2,2.1-2.2s2.1,1,2.1,2.2v2.8C4.3,80.7,3.3,81.7,2.1,81.7z"
		/>
		<path
			fill={color}
			d="M19.3,76c-1.2,0-2.1-1-2.1-2.2V41.4c0-1.2,1-2.1,2.1-2.1s2.1,1,2.1,2.1v32.5C21.5,75.1,20.5,76,19.3,76z"
		/>
		<path
			fill={color}
			d="M36.5,20.9c-1.2,0-2.2-1-2.2-2.1V16c0-1.2,1-2.1,2.2-2.1s2.2,1,2.2,2.1v2.8C38.7,19.9,37.7,20.9,36.5,20.9z"
		/>
		<path
			fill={color}
			d="M19.3,32.2c-1.2,0-2.1-1-2.1-2.1v-2.8c0-1.2,1-2.1,2.1-2.1s2.1,1,2.1,2.1v2.8C21.5,31.2,20.5,32.2,19.3,32.2z"
		/>
		<path
			fill={negative}
			d="M32.2,47.8c-1.2,0-2.1-1-2.1-2.2V18.8c0-1.2,1-2.1,2.1-2.1s2.2,1,2.2,2.1v26.8C34.4,46.8,33.4,47.8,32.2,47.8z
		"
		/>
		<path
			fill={negative}
			d="M40.9,30.8c-1.2,0-2.2-1-2.2-2.1V10.3c0-1.2,1-2.1,2.2-2.1s2.2,1,2.2,2.1v18.3C43,29.8,42,30.8,40.9,30.8z"
		/>
		<path
			fill={color}
			d="M53.8,37.9c-1.2,0-2.2-1-2.2-2.1V17.4c0-1.2,1-2.1,2.2-2.1s2.2,1,2.2,2.1v18.3C55.9,36.9,54.9,37.9,53.8,37.9z
		"
		/>
		<path
			fill={negative}
			d="M49.4,44.9c-1.2,0-2.2-1-2.2-2.1V2.1c0-1.2,1-2.1,2.2-2.1s2.2,1,2.2,2.1v40.6C51.6,44,50.6,44.9,49.4,44.9z"
		/>
		<path
			fill={negative}
			d="M23.7,64.7c-1.2,0-2.1-1-2.1-2.1l0-11.3c0-1.2,1-2.2,2.1-2.2c0,0,0,0,0,0c1.2,0,2.1,1,2.1,2.1l0,11.3
		C25.8,63.8,24.9,64.7,23.7,64.7C23.7,64.7,23.7,64.7,23.7,64.7z"
		/>
		<path
			fill={negative}
			d="M23.7,64.7c-1.2,0-2.1-1-2.1-2.1l0-11.3c0-1.2,1-2.2,2.1-2.2c0,0,0,0,0,0c1.2,0,2.1,1,2.1,2.1l0,11.3
		C25.8,63.8,24.9,64.7,23.7,64.7C23.7,64.7,23.7,64.7,23.7,64.7z"
		/>
		<path
			fill={negative}
			d="M23.7,74.6c-1.2,0-2.1-1-2.1-2.2v-2.8c0-1.2,1-2.2,2.1-2.2s2.1,1,2.1,2.2v2.8C25.8,73.7,24.9,74.6,23.7,74.6z"
		/>
		<path
			fill={negative}
			d="M23.7,74.6c-1.2,0-2.1-1-2.1-2.2v-2.8c0-1.2,1-2.2,2.1-2.2s2.1,1,2.1,2.2v2.8C25.8,73.7,24.9,74.6,23.7,74.6z"
		/>
		<path
			fill={negative}
			d="M15,73.2c-1.2,0-2.1-1-2.1-2.2v-7c0-1.2,1-2.2,2.1-2.2s2.1,1,2.1,2.2v7C17.2,72.3,16.2,73.2,15,73.2z"
		/>
		<path
			fill={negative}
			d="M15,73.2c-1.2,0-2.1-1-2.1-2.2v-7c0-1.2,1-2.2,2.1-2.2s2.1,1,2.1,2.2v7C17.2,72.3,16.2,73.2,15,73.2z"
		/>
		<path
			fill={negative}
			d="M6.4,80.3c-1.2,0-2.1-1-2.1-2.2v-5.6c0-1.2,1-2.2,2.1-2.2s2.1,1,2.1,2.2v5.6C8.6,79.3,7.6,80.3,6.4,80.3z"
		/>
		<path
			fill={negative}
			d="M6.4,80.3c-1.2,0-2.1-1-2.1-2.2v-5.6c0-1.2,1-2.2,2.1-2.2s2.1,1,2.1,2.2v5.6C8.6,79.3,7.6,80.3,6.4,80.3z"
		/>
		<path
			fill={color}
			d="M45.1,0C44,0,43,1,43,2.1v8.2c0,0,0,0,0,0v18.3c0,1.2-1,2.1-2.2,2.1s-2.2-1-2.2-2.1v-1.8v-1
	c0-1.2-1-2.1-2.2-2.1c-1.2,0-2.1,1-2.1,2.1v19.8c0,1.2-1,2.2-2.2,2.2s-2.1-1-2.1-2.2v-2.2v-9c0-1.2-1-2.1-2.1-2.1s-2.1,1-2.1,2.1
	v31.1c0,1.2,1,2.2,2.1,2.2s2.1-1,2.1-2.2v-8.5c0,1.2,1,2.2,2.1,2.2c1.2,0,2.1-1,2.1-2.1v2.8c0,1.2,1,2.2,2.2,2.2s2.2-1,2.2-2.2V48.4
	c0,1.2,1,2.2,2.2,2.2s2.2-1,2.2-2.2v-5.6c0,1.2,1,2.1,2.1,2.1c1.2,0,2.2-1,2.2-2.1V2.1C47.3,1,46.3,0,45.1,0z"
		/>
	</svg>
)

Abstract.propTypes = {
	color: PropTypes.string,
	negative: PropTypes.string,
	transform: PropTypes.string,
}

export default Abstract
